.MuiCheckbox-colorSecondary.Mui-checked {
  color: $primary !important;
}

.input-white {
  border: 0;
  border: 1px $inputBorderGrey solid;
  &:focus {
    outline: none;
    border-color: pink;
  }
}
.cases tbody {
  tr {
    td {
      padding: "14px 10px";
      a.undecorated-link:link, a.undecorated-link:visited {
        color: $black;
      }
    }
    td.link {
      cursor: pointer;
    }
  }
  tr:hover {
    background-color: lighten($primary, 30%) !important;
  }
}
