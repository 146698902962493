// Material UI Overwrites
.MuiFormControl-root {
  display: block;
  width: 100%;

  input::placeholder {
    color: #69727b !important;
  }

  .MuiInput-input {
    width: calc(100% - 38px);
    border: 1px solid $inputBorderGrey;
    display: block;
    padding: 7px 26px 7px 12px;
    font-size: 16px;
    transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: Source Sans Pro;
    border-radius: 4px;
  }
  .MuiInput-underline:before {
    border-bottom: none;
  }
  .MuiInputBase-multiline {
    padding: 6px 0 0;
  }
  select {
    display: block;
    border-radius: 0px;
    width: 100%;
    border: 1px solid #ced4da;
    font-size: 16px;
    padding: 0.5rem 1.5rem 0.5rem 0.75rem;
    option {
      border-radius: 0;
    }
  }
}
