// Fields where multiple tags can be selected
.rbt-input-multi {
  padding: 6px 10px !important;
  input {
    color: $white;
    &::placeholder {
      color: $white !important;
    }

    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23FFFFFF'><polygon points='0,0 100,0 50,50'/></svg>")
      no-repeat;
    background-repeat: no-repeat;
    background-size: 9px auto;
    background-position: calc(100%) center;
  }

  .rbt-token {
    background-color: $primary;
    color: $white;
    font-size: 0.9rem;
    &:hover {
      background-color: darken($primary, 10%);
    }
  }
}

.modal-body {
  max-height: calc(100vh - 190px);
  overflow-y: auto;
  .MuiSvgIcon-root {
    fill: $black;
  }
  .MuiSelect-select.MuiSelect-select {
    color: $black;
  }
  .top-div {
    margin-bottom: 0.5rem;
  }
  ul {
    padding: 0;
    li {
      list-style: none;
    }
  }
}
