.btn-rounded {
  border-radius: 38px;
  padding: 0px 8px;
  font-size: 0.8rem;
}

.btn-sm {
  min-width: 3.5rem;
}

.action-buttons {
  .btn {
    margin: 0 $gutter $gutter 0;
    padding: 3px 30px;
  }
}

.email-buttons {
  .btn {
    margin: 0 0.3rem;
  }
}

.badge-success {
  background-color: #d8f1e8;
  border: 2px darken(#d8f1e8, 2%) solid;
  color: #6c7874;
  border-radius: 20px;
}

.badge-pill {
  display: block;
  padding: 8px;
  font-size: 0.8em;
}
