

.user-guide {
  .sidenav {
    background-color: $background;
    flex: 0 0 25%;
    height: calc(100vh - #{$navbarHeight});
    .sidenav-content {
      font-size: 1.1rem;
      color: $textDark;
      overflow: auto;
      height: 100%;
      h2 {
        margin-bottom: 1.1rem;
      }
      ol {
        counter-reset: item;
        padding-inline-start: 0.5rem;
        list-style-position: outside;
        li {
          display: table-row;
        }
        li:before {
          content: counters(item, ".") ". ";
          counter-increment: item;
          display: table-cell;
          text-align: right;
          padding-right: 0.2rem;
          font-weight: 600;
        }
      }
      a:link,
      a:visited {
        color: $textDark;
      }
      a.selected {
        font-weight: 600;
      }
    }
  }
  main {
    padding: 1rem;
    height: calc(100vh - #{$navbarHeight});
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    header {
      overflow: visible;
      margin-bottom: 1.5rem;
      nav {
        float: right;
        margin: 0.5rem 7rem;
        a:link,
        a:visited {
          margin: 0.5rem;
          padding: 0 0.75rem;
          svg {
            font-size: 2rem;
          }
        }
      }
      h2 {
        text-align: center;
        font-size: 2rem;
      }
      h3 {
        padding-left: 1rem;
        font-size: 1.9rem;
      }
      h4 {
        padding-left: 2rem;
        font-size: 1.8rem;
      }
      h5 {
        padding-left: 3rem;
        font-size: 1.7rem;
      }
    }
    div#scroll-container {
      overflow: auto;
      section {
        font-size: 1.2rem;
        padding-left: 1rem;
        margin-right: 5rem;
        table {
          margin-bottom: 2rem;
        td, th {
          border-style: solid;
          border-width: 0.5px;
          padding: 0.3rem 0.5rem;
          vertical-align: top;
        }
      }
        img {
          padding: 1rem 2rem;
        }
      }
    }
  }
}
