.sidenav {
  background-color: $backgroundSecondary;
  color: $white;
  height: calc(100vh - #{$navbarHeight});
  padding: $gutter;
  display: none;
  flex: 0 0 13%;
  overflow-y: auto;
  width: 13%;

  &.open {
    display: block;
  }
  &::-webkit-scrollbar {
    background-color: $backgroundSecondary;
    width: 13px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: darken($backgroundSecondary, 7%);
  }

  label {
    margin-bottom: 0.4rem;
    font-size: 0.9rem;

    &.client-disabled {
      color: $textLighter;
    }
  }
  .form-control {
    background-color: transparent;
    padding: 0px 11px;
    height: 34px;
    color: $white;
  }

  .MuiCheckbox-root {
    color: $white;
    margin-left: -12px;
  }

  .Mui-disabled {
    .MuiSelect-select.MuiSelect-select {
      color: $textLighter;
      border-color: $textLighter;
    }

    .MuiSelect-icon.Mui-disabled {
      color: $textLighter !important;
    }
  }

  .MuiSelect-icon {
    right: 3px !important;
    color: $white !important;
  }

  .MuiSelect-select {
    width: calc(100% - 38px);
    color: $white;
  }
}
