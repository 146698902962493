$border-radius: 20px;

.bubble {
  white-space: pre-wrap;
  width: 40%;
  padding: calc(#{$gutter} / 2) $gutter;
  margin-bottom: $gutter;

  border-radius: $border-radius $border-radius $border-radius 0;

  &.bubble-right {
    margin-left: auto;
    border-radius: $border-radius $border-radius 0 $border-radius;
  }

  &.bubble-primary {
    background-color: $primary;
    color: $white;
    padding: $gutter;
  }

  &.bubble-default {
    background-color: $defaultColour;
    color: $textDark;
    padding: $gutter;
  }

  &.bubble-text {
    background-color: unset;
    color: $textDark;
    padding: $gutter;
  }

  &.bubble-sending {
    background-color: $defaultColour;
    color: darken($white, 30%);
  }
}

.bubble-external {
  font-size: 0.8rem;
  color: $textLighter;
  margin-top: -$gutter;
  padding: 5px 0;
  font-weight: 300;

  &.bubble-external-right {
    text-align: right;
  }
}
