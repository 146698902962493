.navbar {
  padding: 0;
  display: flex;
  height: $navbarHeight;
  .brand {
    width: 13%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 $gutter;
    background-color: darken($backgroundSecondary, 10%);
    color: $white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.2rem;
    letter-spacing: 1.5px;
    display: flex;
    justify-content: space-between;
    i {
      font-size: 1rem;
      &:hover {
        cursor: pointer;
        color: darken($color: #ffffff, $amount: 10%);
      }
    }
  }
  .nav {
    flex: 1 1 auto;
    height: 100%;
    background-color: $white;

    display: flex;
    justify-content: space-between;
    .menu {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      li {
        a {
          display: flex;
          align-items: center;
          color: $black;
          height: 100%;
          padding: 0 $gutter;
          padding-bottom: 3px;

          &.active {
            font-weight: bold;
            border-bottom: $primary 3px solid;
            padding-bottom: 0;
          }
          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .menu-right {
      display: flex;
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      padding-right: $gutter;
      li {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 $gutter;
        position: relative;
        button {
          color: $black;
          padding: 0;
          &:hover {
            color: lighten($black, 10%);
            text-decoration: none;
          }
        }
        a {
          svg {
            color: $textGrey;
            margin-right: -0.3em;
            font-size: 1.8em;
          }
        }

        &.dropdown {
          a,
          button {
            i {
              color: $textGrey;
            }
          }

          > a {
            position: relative;
            z-index: 4;
            width: 100%;
            height: 100%;
          }

          &::before {
            content: "";

            background-image: url("../../images/dropdown-arrow.svg");
            background-size: 100% 100%;

            position: absolute;
            width: 100%;
            height: 47px;
            right: 50%;
            top: calc(100% + 2px);
            transform: translate(50%, -50%);
            z-index: 3;
          }
          .menu-dropdown {
            position: absolute;
            top: 100%;
            right: 0;
            background-color: $white;
            min-width: 450px;
            -webkit-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
            -moz-box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
            z-index: 3;

            &.menu-dropdown-small {
              min-width: 200px;
            }

            .menu-dropdown-content {
              width: 100%;
              height: 100%;
              background-color: $white;
              position: relative;
              z-index: 5;

              .account-menu {
                padding: 0;
                li {
                  border-bottom: 1px $background solid;
                  &:last-of-type {
                    border: none;
                  }
                  margin: 0 $gutter;
                  padding: 0;
                  button,
                  a {
                    padding: $gutter 5px;
                    color: $black;
                    width: 100%;
                    text-align: left;
                    &:hover {
                      text-decoration: none;
                    }
                  }

                  .version {
                    padding: $gutter 5px !important;
                    color: $textLighter;
                    width: 100%;
                    text-align: left;
                    font-size: 0.9rem;
                  }
                }
              }
            }

            .menu-header-tabs {
              color: $textDark;
              display: flex;
              list-style: none;
              margin-bottom: 0;
              padding-left: 0;
              align-items: center;
              margin: 0 25px;
              li {
                margin-right: 15px;
                padding: 15px 13px;
                &.active {
                  font-weight: bold;
                  border-bottom: 2px $primary solid;
                }
              }
            }

            .menu-body {
              margin: 0 25px;
              .case {
                color: $textDark;
                display: flex;
                justify-content: space-between;
                padding: 20px 0;
                border-bottom: 1px $background solid;
                .case-content {
                  .case-title {
                    font-weight: 700;
                    line-height: 1;
                  }
                  .case-team-name {
                    line-height: 1.8;
                    font-size: 0.99rem;
                  }
                  .case-details {
                    color: $textLighter;
                    padding-top: 4px;
                    font-size: 0.8rem;
                  }
                }
                .case-buttons {
                  align-self: center;
                  padding-left: $gutter;
                }
              }
            }
            .menu-footer {
              text-align: center;
              padding: 14px;
            }
          }

          &.closed {
            &::before {
              content: none;
            }
            .menu-dropdown {
              display: none;
            }
          }
        }
      }

      .divider {
        background-color: #ebebf2;
        width: 2px;
        height: 20px;
        padding: 0;
        margin: auto 0;
      }

      .user {
        display: flex;
        align-items: center;

        i {
          padding: 0 13px;
          font-size: 0.7rem;
          color: $textGrey;
        }
        .default-profile-pic {
          display: flex;
          align-items: center;
          justify-content: center;

          background-color: $backgroundSecondary;
          color: $white;
          font-weight: 300;
          border-radius: 50%;
          height: 35px;
          width: 35px;
          margin-left: 5px;
        }
        .profile-pic {
          border-radius: 50%;
          height: 35px;
          width: 35px;
        }
      }
    }
  }
}
