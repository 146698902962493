.page-layout {
  display: flex;

  .sidenav {
    display: none;
    width: 13%;
    flex: 0 0 13%;
    background-color: $backgroundSecondary;
    color: $white;
    padding: 0;

    &::-webkit-scrollbar {
      background-color: $backgroundSecondary;
      width: 13px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: darken($backgroundSecondary, 7%);
    }

    .sidenav-content {
      padding: $gutter;
    }

    &.open {
      display: block;
    }
  }

  .content {
    padding: calc(#{$gutter} * 2);
    flex: 1 1 auto;
    z-index: 0;
    height: calc(100vh - #{$navbarHeight});
    overflow-y: scroll;

    .content-container {
      background-color: $white;
      height: 100%;
      overflow: auto;
    }
  }
}

.remaining {
  border-bottom: $underlineGrey 3px solid;
  text-align: right;
  padding: 0;
  .badge {
    display: inline;
    padding: 5px 30px;
    font-size: 0.9rem;
  }
}

.details-section {
  background-color: darken($backgroundSecondary, 5%);
  margin-left: -$gutter;
  margin-top: -$gutter;

  margin-bottom: calc(#{$gutter} * 2);

  width: calc(100% + (#{$gutter} * 2));
  padding: $gutter;
  .details-title {
    font-size: 1.3rem;
  }
  .details-item {
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: $gutter;
    font-weight: 300;
  }
}

.fixed-height {
  height: calc(100% - 42px);
  > div {
    height: 100%;
  }
}

.note-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .email-messages {
    flex: 1 1 auto;
    overflow: auto;
    height: 50%;
  }
}

.message-image {
  span.image {
    width: 45px;
    height: 45px;
    margin: 10px 15px 10px 15px;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: $backgroundSecondary;
    color: $white;
    font-weight: 500;
    border-radius: 50%;
    text-transform: uppercase;
  }
}

.email-container {
  height: 100%;
  display: flex;
  .email-messages {
    width: 20%;
    flex: 0 0 auto;
    border-right: 1px $background solid;

    .email-message {
      border-bottom: 1px $background solid;
      display: flex;
      align-items: center;

      text-align: unset;
      overflow: hidden;
      width: 100%;

      &.active {
        background-color: $defaultColour;
      }

      &:hover {
        background-color: lighten($defaultColour, 3%);
      }

      .message-content {
        margin: 10px 10px 10px 0;
        overflow: hidden;
        flex: 1 1 auto;
        .name {
          font-weight: 600;
          font-size: 0.9rem;
          line-height: 19px;
        }
        .subject {
          font-size: 0.9rem;
          line-height: 19px;
        }

        .status {
          float: right;
          font-size: 0.8rem;
          color: darken($defaultColour, 35%);
          display: flex;
          align-items: center;
          margin-top: 5px;
          i {
            font-size: 0.6rem;
          }
        }
      }
    }
  }

  .email-content {
    padding: 40px;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .message {
      flex: 1 1 auto;
      .message-title {
        padding-bottom: 20px;
        border-bottom: 1px $background solid;
        flex: 0 0 auto;
        .subject {
          font-size: 1.1rem;
          margin-bottom: 10px;
        }
        .info {
          display: flex;

          .message-image {
            .image {
              margin-left: 0;
            }
          }

          .details {
            .email-pill {
              background-color: $defaultColour;
              padding: 3px 7px;
              margin-left: 5px;
              border-radius: 20px;
            }
            flex: 1 1 auto;
            .top {
              display: flex;
              align-items: center;
              .name {
                flex: 1 1 auto;
              }
              .date {
                font-size: 0.9rem;
              }
            }
            .to,
            .cc,
            .attachments {
              margin-top: 5px;
              font-size: 0.8rem;
              a {
                padding: 1px 4px;
              }
            }
          }
        }
      }
      .message-body {
        padding: 40px 20px 40px 0;
      }
    }

    .email-input {
      flex: 0 0 auto;
      background-color: $defaultColour;
      .content-container {
        background-color: $defaultColour;
      }
      .input-white {
        background-color: $defaultColour;
        border: none;
      }
      .rbt {
        .rbt-input-multi {
          background-color: $defaultColour;
          border: none;
        }
        .rbt-input-main::placeholder {
          color: $textLighter !important;
        }
      }
    }
  }
}

.email-input {
  flex: 0 0 auto;
  padding: $gutter $gutter calc(#{$gutter} - 5px) $gutter;
  display: flex;
  flex-direction: column;

  .content-container {
    flex: 1 1 auto;
    height: unset;
    display: flex;
    flex-direction: column;
    .body {
      flex: 1 1 auto;
      textarea {
        height: 100%;
        min-height: 150px;
        resize: none;
      }
    }
  }
}

.notes {
  height: 50%;
  display: flex;
  flex-direction: column;

  .note {
    font-size: 0.97rem;

    &.note-sending {
      color: $textLighter;
    }

    small {
      color: $textLighter;
      display: block;
      font-style: italic;
      font-weight: 300;
    }
  }
}

.email-actions {
  padding-top: $gutter;
}
.note-actions-row,
.email-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .media {
    i {
      padding-right: 10px;
      color: $textGrey;

      &:hover {
        cursor: pointer;
        color: darken($textGrey, 10%);
      }
    }
  }

  .save-button {
    .btn {
      padding: 0.25rem 30px;
    }
  }
}

.teamq.page-layout {
  .content-container {
    height: calc(100% - 89px);
  }
}

.history-check {
  display: inline;
  padding-right: $gutter;
}
