.dashboard-loading {
  &.content {
    padding: 0;
    z-index: 99999;
    margin-top: -3px;
    overflow: hidden;
  }

  .loading {
    width: 100%;
    height: 3px;
    .loading-bar {
      background-color: $primary;
      height: 100%;

      transform: translateX(-100%);
      animation: spin 3s ease-in-out infinite;
    }
  }
}

@keyframes spin {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
