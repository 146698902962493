.tabs {
  margin: $gutter 0 0 0;
  padding: 0;
  list-style: none;
  display: flex;

  &:after {
    content: "";
    flex: 1 1 auto;
    border-bottom: $underlineGrey 3px solid;
  }
  li {
    a,
    button {
      display: flex;
      align-items: center;
      color: $black;
      height: 100%;
      padding: 0 $gutter;
      padding-bottom: 3px;
      font-weight: 400;
      font-size: 0.9rem;
      border-bottom: $underlineGrey 3px solid;

      &:hover {
        text-decoration: none;
        border-bottom: darken($underlineGrey, 10%) 3px solid;
      }
      &.active,
      &.active &:hover {
        font-weight: bold;
        border-bottom: $primary 3px solid;
      }
    }
  }
}

.tab-title {
  padding: $gutter 0;
}

.email-messages {
  .tab-title {
    padding-top: 0;
  }
}
