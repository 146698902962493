.chart-relative {
  position: relative;
  .chart-title {
    position: absolute;
    top: 50%;
    // left: calc(50% - 38px);
    left: 50%;
    transform: translate(-50%, -50%);

    text-align: center;
    font-size: 1.8rem;
    .c-title,
    .total {
      line-height: 35px;
    }
    .c-title {
      display: block;
      // padding-bottom: 10px;
    }
    .total {
      font-size: 2rem;
    }
    i {
      padding: 5px 0;
      display: block;
      color: #bcbccb;
    }
  }
}

.sla-summary-chart-header {
  font-size: 1.2rem;
  text-align: center;
  margin: 0 0 $gutter 0;
}

.chart-heading {
  font-size: 1.4rem;
}

.chart-flex {
  display: flex;
  align-items: center;

  .chart-section {
    flex: 1 1 auto;
    width: calc(100% - 30%);
  }
  .legend-section {
    width: 30%;
    flex: 0 0 auto;
    padding-left: 20px;

    .legend-item {
      display: flex;
      align-items: center;
      padding: 5px 0;
      .legend-box {
        border: 1px #707070 solid;
        height: 20px;
        width: 20px;

        &.legend-box-success {
          background-color: #25a45d;
        }
        &.legend-box-warning {
          background-color: #ffc107;
        }
        &.legend-box-danger {
          background-color: #ef626c;
        }
      }
      .legend-label {
        width: calc(100% - 20px);
        text-align: right;
        color: #bcbccb;
      }
    }
  }
}

.chart-padding {
  padding: 15px;
}

.chart-center {
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    color: $textLighter;
    font-size: 1.5rem;
  }
}
