.scanx-input {
  .error-message {
    background-color: #f4c8cb;
    border-radius: 15px;
    color: $danger;
    margin-top: 20px;
    padding: 20px;
  }

  .form-field {
    margin-bottom: 30px;
  }

  .input-form {
    padding: 20px 0;
  }

  .success-message {
    color: $success;
  }

  .validation-message {
    color: $danger;
  }
}

.scanx-output {
  .download-link-wrapper {
    width: 400px;

    .scanx-upload-download {
      margin-right: 20px;
    }
  }
  .run-time-from-now {
    font-style: italic;
  }
}
