.dashboard {
  .dashboard-tabs {
    margin: 0 0 20px 0;
  }

  .polling-table {
    background-color: $white;
    td,
    th {
      font-family: Source Sans Pro, sans-serif;
    }
  }

  .poll-time-from-now {
    font-style: italic;
  }
}
