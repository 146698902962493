.actions-container {
  padding: calc(#{$gutter} * 2);
  .actions-title {
    font-weight: 700;
    font-size: 1.1rem;
    color: $textDark;
    padding-top: $gutter;
    border-top: 1px $background solid;
  }
  .actions {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    li {
      margin-top: $gutter;
      a {
        color: $textDark;
      }
    }
  }
}
