.note-input {
  background-color: transparent;
  border: 0;
  width: 100%;
  resize: none;
  padding: $gutter 0;
  &::placeholder {
    color: $primary;
    font-size: 0.9rem;
  }

  &:focus {
    outline: transparent;
  }
}

.email-input {
  .flex-input {
    display: flex;
    align-items: center;
    label {
      margin-bottom: 0;
      padding-right: $gutter;
    }
    input,
    textarea {
      flex: 1 1 auto;
    }
  }
  .rbt {
    flex: 1 1 auto;
    .rbt-input-multi {
      padding: 2px 2px !important;
      margin: 3px 0;
      border: 1px $inputBorderGrey solid;
      &:focus,
      &.focus {
        border: 1px pink solid;
        box-shadow: none;
      }
      input.rbt-input-main {
        color: $black;
      }
    }
  }
}
.document-view {
  iframe { 
    flex-grow: 1;
    border: darken($underlineGrey, 10%) 3px inset;
    margin: 0;
    padding: 0;
  }  
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;  
}
